<template>
  <div class="form" >

    <CodeLogin v-show="!telPhone" />
    <PhoneLogin v-show="telPhone" />


    <div class="wei">
      <div class="dec">
        快捷登录
      </div>
      <div class="wei_logo">
        <svg @click="telPhone = true" id="手机" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="57.59" height="57.562" viewBox="0 0 57.59 57.562">
          <path id="椭圆_1618" data-name="椭圆 1618" class="cls-1l" d="M1066.67,743.216A28.784,28.784,0,1,1,1037.89,772,28.787,28.787,0,0,1,1066.67,743.216Z" transform="translate(-1037.88 -743.219)"/>
          <image id="矢量智能对象" x="20.12" y="16.781" width="20" height="25" xlink:href="data:img/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAZCAYAAAAxFw7TAAAAyklEQVQ4je3VOw5BURRG4bWvR6JUSDSYgEKhMQExAZ1GrVCrVAoGYAomYB4SjcIE1BIdftlCouJil3clpzv5su8j5+BJGks66Pf2kvpPbPAH9NpVUjMBhsRkQM/BahDoVZJAzEuiQTIwAzMwTSZpDuwCrBxQ8POwHTWdpI5PuAHKAZ5PuMoDRaDxZuMVmAB1YPQBtTQfZWtmC2D8wN/mE54+7GlJmgG1FH/F0V/kNOiS8roOliStA7ClJPOb6p4kf3xf3ybgbGYXgBt5DlEKUiJ1GAAAAABJRU5ErkJggg=="/>
        </svg>
        <svg @click="weixinLogin"  id="微信" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="57.594" height="57.562" viewBox="0 0 57.594 57.562">
          <path id="椭圆_1618" data-name="椭圆 1618" class="cls-1" d="M858.326,743.216A28.784,28.784,0,1,1,829.542,772,28.784,28.784,0,0,1,858.326,743.216Z" transform="translate(-829.531 -743.219)"/>
          <image id="矢量智能对象" x="13.469" y="15.781" width="31" height="25" xlink:href="data:img/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB8AAAAZCAYAAADJ9/UkAAACSUlEQVRIia2Wz4tOURjHP8e8mprJj8wowkKmRiihxoYFCSsbUoSVZjNSFpZq/gIbFvZTSg2SDRsLzSgzRIoaTaaEppHJ78mMmY+O7qvruu+P+76+m/e+z7nP+Tz3nOd5zglUkLoLOAzE325gCbAATAMvgWFgMIQwUmmOwlL3q0+sX0+jT7PQFvVyAWhWA2pbo+AbTYDLul84ALU/Z6I36m11pgJoVB3OsV8tAl6vzmYmmFKXJeOncwDXUv53csYP1MNeBPQBizP2lpStNcevpJYrpZQzfr4eeFCfA5tyxmJJPUtKLQ8wBnwHtiX/PwKPgSlgBugNIczXgs8nK9CobgEXgVGgB9gMdAJzwCTwIIQwljt3fUmcq0l1r7pWvaR+rfLumNqrlrLwqQbAb9Uu9bj6uYDfiLohDb9ZEPxT3an2FfQr673aXYYfKuh8Rd2eBJGddDrn/bg9nzK2F2prhMeku1cAvjFpPml9UVeo7eq7lD0+t6mdOdtzphRCZHsCiKfTmhoZ/zoppYMZe2yp+4BYOctT9vi8B+gA2jM+J9N7HxNoosZX31V3F1ilapr7U98hhHFgR3JWV9JCzhc0qlK2uXwDVlWZbF2y9P9Dr7JtM14KlgIPgX5gCNgKdCVBtYQQYqaOJ7ZmNPiXb8xANZtM/0g91+R+f1BXNhR4bJPqUIPg+Xo+sFYAHRUuE9X0Qz3aFDizAheSJlOPzqb9K16dCwYRm8mxpNFsSY7UWWACeASsBo4AAyGEU/+DWTTAHvV6vKz+NgC/ACueVbr7RTZPAAAAAElFTkSuQmCC"/>
        </svg>
      </div>
    </div>
    <div class="toggle">
      <div class="btn" @click="$router.push('/reset')">忘记密码?</div>
      <div class="btn" @click="changeLogin">注册新帐号</div>
    </div>
  </div>
</template>

<script>
  import CodeLogin from './CodeLogin.vue'
  import PhoneLogin from './PhoneLogin.vue'
  import { weiLogin } from '@/utils/network'
  export default {
    data() {
      return {
        //手机号登录控制
        telPhone:false,
      }
    },
    created() {
    },

    methods: {
      changeLogin() {
        this.$store.dispatch('isLogin',{
          isLogin:false
        })
      },
      weixinLogin() {
        // this.$router.replace('/ErrorPage')
        this.qrcodeScan()
      },
      async  qrcodeScan () {//生成二维码
        const { data: res } =  await weiLogin({})
        window.location.href = res.data
      },
    },
    components: {
      PhoneLogin,
      CodeLogin
    }
  }
</script>

<style lang="less" scoped>
  .form {
    width: 288px;
    margin: 10px auto;
    .el-form {
      display: flex;
      flex-direction: column;
      align-items: center;
      .specil {
        display: flex;
        justify-content: space-between;
        .num {
          cursor: pointer;
          width: 114px;
          border-radius: 18px;
          height: 34px;
        }
      }
      .specil1 {
        .send {
          border: none;
          cursor: pointer;
          background-color:red;
          height: 30px;
          width: 130px;
          line-height: 30px;
          text-align: center;
          color: #fff;
          font-size: 12px;
          border-radius: 180px;
        }
        .false {
          border: none;
          cursor: pointer;
          height: 30px;
          width: 130px;
          line-height: 30px;
          text-align: center;
          color: #fff;
          font-size: 12px;
          border-radius: 180px;
          background-color: #aaaaaa;
        }
      }
    }
    .footer {
      width: 270px;
      margin: 5px auto;
      display: flex;
      text-align: center;
      justify-content: space-around;
      .btn  {


        width: 186px;

        border-radius: 18px;
      }
      .forget {
        cursor: pointer;
        padding-top: 20px;
        font-size: 12px;
        color: rgba(0, 0, 0, .7);
        display:table-cell;
        vertical-align:bottom;
      }
    }
    .wei {
      margin: 25px auto;
      text-align: center;
      .wei_logo {

        display: flex;
        justify-content: space-around;
        margin-top: 12px;
        cursor: pointer;
        .cls-1 {
          fill: #07c160;
          fill-rule: evenodd;
        }
        .cls-1l {
          fill: #e78d00;
          fill-rule: evenodd;
        }
      }
    }
    .toggle {
      display: flex;
      justify-content: space-between;
      .btn {
        cursor: pointer;

      }

    }
  }

</style>
