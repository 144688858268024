<template>
  <div class="registerForm">
    <el-form :model="registerForm" :rules="registerRules" ref="ruleForm">
      <el-form-item prop="telephoneNumber">
        <el-input  v-model="registerForm.telephoneNumber" placeholder="请输入手机号"></el-input>
      </el-form-item>
<!--      <el-form-item prop="imgCode">-->
<!--        <div class="specill">-->
<!--&lt;!&ndash;          <el-input  type="text" class="sp_input" v-model="registerForm.imgCode" placeholder="请输入验证码"></el-input>&ndash;&gt;-->
<!--&lt;!&ndash;          <img class="num" :src="codeRegImg" alt="验证码" @click="codeRegClick">&ndash;&gt;-->
<!--        </div>-->
<!--      </el-form-item>-->
      <el-form-item prop="smsCode">
        <div class="specil">
          <el-input v-model="registerForm.smsCode" placeholder="请输入短信验证码"></el-input>
          <div :class="[btnFlag ? 'false' :'sended']" :disabled="btnFlag" @click.passive="sendPhoneCode">{{ result > 0 ? `${result}s` : "获取验证码"}}</div>
        </div>

      </el-form-item>
      <!-- <el-form-item prop="name">
         <el-input  v-model="registerForm.code" placeholder="请输入验证码"></el-input>
       </el-form-item>-->
      <el-form-item prop="password">
        <el-input  type="password" v-model="registerForm.password" placeholder="请输入密码"></el-input>
      </el-form-item>
      <el-form-item prop="comfirePassword">
        <el-input  type="password" v-model="registerForm.comfirePassword" placeholder="确认密码"></el-input>
      </el-form-item>
    </el-form>
    <Verify @success="goRegister"
            :mode="'pop'"
            :captchaType="'blockPuzzle'"
            :imgSize="{ width: '330px', height: '155px' }"
            ref="verify">
    </Verify>
    <el-button class="register_btn" type="primary" @click="goVerify">注册</el-button>

  </div>
</template>

<script>
  import { reqRegister, reqImgCode,  reqTelCode} from '@/utils/network'
  import {Encrypt} from '@/utils/secret.js'
  import _ from 'lodash'
  import Verify from "@/components/verifition/Verify";
  export default {
    data() {
      var validatePass2 = (rule, value, callback) => {
        if (value === '') {
          callback(new Error('请再次输入密码'));
        } else if (value !== this.registerForm.password) {
          callback(new Error('两次输入密码不一致!'));
        } else {
          callback();
        }
      };
      var checkPhone = (rule, value, callback) => {
        if (!value) {
          return callback(new Error('手机号不能为空'));
        } else {
          const reg = /^1[3|4|5|7|8|9][0-9]\d{8}$/
          //console.log(reg.test(value));
          if (reg.test(value)) {
            callback();
          } else {
            return callback(new Error('请输入正确的手机号'));
          }
        }
      };
      return {
        btnFlag: false,
        timer: null,   //定时器
        result: null,   //此变量为截止时间戳与当前时间戳相减的差
        timeStamp: null,//此变量为倒计时截止的时间戳
        //注册表单数据
        registerForm:{
          telephoneNumber:'',
          password:'',
          comfirePassword:'',
          // imgCode:'',
          smsCode:'',
          uid:''
        },
        //注册表单验证
        registerRules:{
          // imgCode:[{ required: true, message: '请输入图形验证码', trigger: 'blur' },],
          smsCode:[{ required: true, message: '请输入手机验证码', trigger: 'blur' },],
          password:[
            { required: true, message: '请输入密码', trigger: 'blur' },
            { min: 8, max: 20, message: '长度在 8 到 20 个字符', trigger: 'blur' }
          ],
          comfirePassword:[
            { validator: validatePass2, trigger: 'blur' }
          ],
          telephoneNumber:[
            { validator: checkPhone, trigger:'blur'}
          ]


        },
        //二维码弹框控制
        //qrcodeVisible:false,
        codeRegImg:'',

      }
    },
    created() {
      this.initTimer()
      this.codeRegClick()
    },
    mounted() {
      if (this.result) this.countTime();
    },
    methods: {
      //注册切换验证码
      async codeRegClick() {
        const {data: res} = await reqImgCode()
        //console.log(res)
        if(res.code !== 200) return this.$message.error('获取验证码错误')
        this.registerForm.uid = res.data.uid
        this.codeRegImg = res.data.imgBase64String

      },
      goVerify(){
        this.$refs.ruleForm.validate(async flag => {
          if (flag) {
            this.$refs.verify.show()
          } else {
            this.$message.error('请填写完整表单')
          }
        })
      },
      //注册的点击事件
      async  goRegister() {
        this.$refs.ruleForm.validate(async flag => {
          if(flag) {
            const obj = {}
            _.defaultsDeep(obj, this.registerForm)
            obj.password = Encrypt(obj.password)
            obj.comfirePassword = Encrypt(obj.comfirePassword)
            const { data: res } = await reqRegister(obj)
            // console.log(res)
            if(res.code !== 200) return  this.$message.error(`${res.message}`)
            this.$store.dispatch('isLogin', {
              isLogin:true
            })
            this.$refs.ruleForm.resetFields()
            this.registerForm.uid = ''
            this.$message.success('注册成功， 请登录')

          }else {
            this.$message.error('请填写完整表单')
          }
        })

      },
      async sendPhoneCode() {
          /* 发送手机验证码 */
          if (this.registerForm.telephoneNumber.trim() !== "") {
            /* 验证邮箱是正确 */
            var reg = /^1[3|4|5|7|8|9]\d{9}$/
            if (reg.test(this.registerForm.telephoneNumber) === true) {
              try {
                const { data: res } = await reqTelCode({
                  telephoneNumber:this.registerForm.telephoneNumber
                })
              if(res.code !== 200) return this.$message.error(`${res.message}`)
              this.countTime()
                // alert(`${res.data}`)
              }catch (e) {
              }

            } else {
              this.$message.error("输入正确的手机号！");
            }
          } else {
            this.$message.error("手机号不能空着呢！");
          }
      },
      initTimer() {
        window.addEventListener('beforeunload',()=>{
          if (this.timeStamp) {
            localStorage.setItem("reduceTimeStamp", JSON.stringify(this.timeStamp));
            clearInterval(this.timer);
          }
        })
        let temp = JSON.parse(localStorage.getItem("reduceTimeStamp"));
        let NowStamp = new Date().getTime();
        //如果<=0说明倒计时已结束，将按钮恢复原始状态
        if (+temp - NowStamp <= 0) {
          this.result = null;
          this.btnFlag = false;
        } else {
          this.result = parseInt((temp - NowStamp) / 1000);
          // console.log(this.result);
        }
      },
      countTime() {
        //如果result<=0,证明上一次读秒已结束，需要重新设置新的时间戳
        this.btnFlag = !this.btnFlag;
        if (!this.result) {
          let currentStamp = new Date().getTime();
          this.timeStamp = new Date().getTime() + 60 * 1000;
          //我设置了30秒
          this.result = Math.floor((this.timeStamp - currentStamp) / 1000);
        }
        this.timer = setInterval(() => {
          this.result--;
          if (this.result === 0) {
            clearInterval(this.timer);
            this.timer = null;
            this.btnFlag = false;
          }
        }, 1000);
      },
    },
    components: {Verify}
  }
</script>

<style lang="less" scoped>
  .registerForm {
    width: 288px;
    margin: 10px auto;
    .specill {
      display: flex;
      justify-content: space-between;
      .num {
        border-radius: 18px;
        cursor: pointer;
        width: 99px;
        height: 33px;
      }
    }
    .specil {
      .false {
        border: none;
        background-color:#ccc;
        height: 30px;
        width: 130px;
        line-height: 30px;
        text-align: center;
        color: #000;
        cursor: auto;
        font-size: 12px;
        border-radius: 180px;
      }
      .sended {
        border: none;
        cursor: pointer;
        background-color:red;
        height: 30px;
        width: 130px;
        line-height: 30px;
        text-align: center;
        color: #fff;
        font-size: 12px;
        border-radius: 180px;
      }
    }
    .register_btn {
      width: 166px;
      margin: 14px 0 0 60px;
      border-radius: 80px;
      /*margin: 14px auto;
      height: 36px;
      line-height: 36px;
      border-radius: 80px;
      text-align: center;
      background-color: #f0141a;
      color: #fff;
      width: 166px;
      font-size: 16px;
      cursor: pointer;*/
    }

  }

</style>
