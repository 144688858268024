<template>
  <div class="form">
    <el-form :model="loginForm2" :rules="loginRules2" ref="loginForm">
      <el-form-item prop="telephoneNumber">
        <el-input  v-model="loginForm2.telephoneNumber" placeholder="请输入手机号"></el-input>
      </el-form-item>
      <el-form-item prop="imgCode">
        <div class="specil1">
          <el-input type="text" v-model="loginForm2.smsCode" placeholder="请输入短信验证码"></el-input>
          <div :class="[btnFlag ? 'false' :'sended']" :disabled="btnFlag" @click="sendPhoneCode">{{ result > 0 ?
              `${result}s` : "获取验证码"}}
          </div>
        </div>
        <div class="specil">
<!--          <el-input type="text" class="sp_input" v-model="loginForm2.imgCode" placeholder="请输入验证码"></el-input>-->
<!--          <img class="num" :src="codeLogImg" alt="验证码" @click="codeLogClick">-->
        </div>
      </el-form-item>
      <el-form-item prop="smsCode">
        <div class="specil1">
<!--          <el-input type="text" v-model="loginForm2.smsCode" placeholder="请输入短信验证码"></el-input>-->
<!--          <div :class="[btnFlag ? 'false' :'sended']" :disabled="btnFlag" @click="sendPhoneCode">{{ result > 0 ?-->
<!--            `${result}s` : "获取验证码"}}-->
<!--          </div>-->
        </div>
      </el-form-item>
    </el-form>
    <Verify @success="goLogin"
            :mode="'pop'"
            :captchaType="'blockPuzzle'"
            :imgSize="{ width: '330px', height: '155px' }"
            ref="verify">
    </Verify>
    <div class="footer">
      <el-button class="btn" type="primary" @click="goVerify">登录</el-button>
    </div>
  </div>
</template>

<script>
  import {reqLogin, reqPhoneLogin, reqImgCode, reqTelCode, getPersonal } from '@/utils/network'
  import ResetTop from "@/components/reset/ResetTop";
  import Footer from "@/components/Footer";
  import Verify from "@/components/verifition/Verify";

  export default {
    data() {
      var checkPhone = (rule, value, callback) => {
        if (!value) {
          return callback(new Error('手机号不能为空'));
        } else {
          const reg = /^1[3|4|5|7|8|9][0-9]\d{8}$/
          //console.log(reg.test(value));
          if (reg.test(value)) {
            callback();
          } else {
            return callback(new Error('请输入正确的手机号'));
          }
        }
      };
      return {

        //验证码激活按钮
        btnFlag: false,
        timer: null,   //定时器
        result: null,   //此变量为截止时间戳与当前时间戳相减的差
        timeStamp: null,//此变量为倒计时截止的时间戳
        //手机验证码登录
        loginForm2: {
          telephoneNumber: '',
          smsCode: '',
          // imgCode: '',
          uid: ''
        },
        //登录表单验证
        loginRules2: {
          telephoneNumber: [{validator: checkPhone, trigger: 'blur'}],
          // imgCode: [{required: true, message: '请输入图形验证码', trigger: 'blur'},],
          smsCode: [{required: true, message: '请输入手机验证码', trigger: 'blur'},],

        },
        //手机号登录控制
        telPhone: false,

        //登录图片验证码的路径
        codeLogImg: ''
      }
    },
    created() {
      this.initTimer()
      this.codeLogClick()
      this.codeLogClick()
    },
    mounted() {
      if (this.result) this.countTime();
    },
    components: {
      Verify
    },
    methods: {
      //登录切换验证码
      async codeLogClick() {
        const {data: res} = await reqImgCode()
        if (res.code !== 200) return this.$message.error('获取验证码错误')
        this.codeLogImg = res.data.imgBase64String
        this.loginForm2.uid = res.data.uid
      },
      goVerify(){
        this.$refs.loginForm.validate(async flag => {
          if (flag) {
            this.$refs.verify.show()
          } else {
            this.$message.error('请填写完整表单')
          }
        })
      },

      //登录的点击事件
      async goLogin() {
        this.$refs.loginForm.validate(async flag => {
          if(flag) {
            const {data: res} = await reqPhoneLogin(this.loginForm2)
            if(res.code !== 200) return this.$message.error(`${res.message}`)
            localStorage.setItem('token', res.data.accessToken )
            this.$store.dispatch('setToken', {
              token: res.data.accessToken,
            })

            const { data: res2 } = await getPersonal({})
            if(res2.code !== 200) return  this.$message.error('网络错误')
            this.$store.dispatch('gerUserInfo', {
              user: res2.data
            })
            this.$refs.loginForm.resetFields()
            this.loginForm2.uid = ''
            this.$store.dispatch('changeDialogShow', {
              loginDialogVisible: false
            })
            this.$message.success('登录成功')
          }else {
            this.$message.error('请填写完整表单')
          }
        })

      },
      async sendPhoneCode() {
        /* 发送手机验证码 */
        if (this.loginForm2.telephoneNumber.trim() !== "") {
          /* 验证邮箱是正确 */
          var reg = /^1[3|4|5|7|8|9]\d{9}$/;
          if (reg.test(this.loginForm2.telephoneNumber) === true) {

            const {data: res} = await reqTelCode({
              telephoneNumber: this.loginForm2.telephoneNumber
            })
            if(res.code !== 200) return this.$message.error(`${res.message}`)
            this.countTime()
            // alert(`${res.data}`)
            // console.log(res)

          } else {
            this.$message.error("输入正确的手机号！");
          }
        } else {
          this.$message.error("手机号不能空着呢！");
        }
      },
      initTimer() {
        window.addEventListener('beforeunload', () => {
          if (this.timeStamp) {
            localStorage.setItem("reduceTimeStamp", JSON.stringify(this.timeStamp));
            clearInterval(this.timer);
          }
        })
        let temp = JSON.parse(localStorage.getItem("reduceTimeStamp"));
        let NowStamp = new Date().getTime();
        //如果<=0说明倒计时已结束，将按钮恢复原始状态
        if (+temp - NowStamp <= 0) {
          this.result = null;
          this.btnFlag = false;
        } else {
          this.result = parseInt((temp - NowStamp) / 1000);
        }
      },
      countTime() {
        //如果result<=0,证明上一次读秒已结束，需要重新设置新的时间戳
        this.btnFlag = !this.btnFlag;
        if (!this.result) {
          let currentStamp = new Date().getTime();
          this.timeStamp = new Date().getTime() + 60 * 1000;
          //我设置了30秒
          this.result = Math.floor((this.timeStamp - currentStamp) / 1000);
        }
        this.timer = setInterval(() => {
          this.result--;
          if (this.result === 0) {
            clearInterval(this.timer);
            this.timer = null;
            this.btnFlag = false;
          }
        }, 1000);
      },
    }
  }
</script>

<style lang="less" scoped>
  .form {
    width: 288px;
    margin: 10px auto;

    .el-form {
      display: flex;
      flex-direction: column;
      align-items: center;

      .specil {
        display: flex;
        justify-content: space-between;

        .num {
          cursor: pointer;
          width: 98px;
          border-radius: 18px;
          height: 34px;
        }
      }

      .specil1 {
        .sended {
          border: none;
          cursor: pointer;
          background-color: red;
          height: 30px;
          width: 130px;
          line-height: 30px;
          text-align: center;
          color: #fff;
          font-size: 12px;
          border-radius: 180px;
        }

        .false {
          border: none;
          background-color: #ccc;
          height: 30px;
          width: 130px;
          line-height: 30px;
          text-align: center;
          color: #000;
          cursor: auto;
          font-size: 12px;
          border-radius: 180px;
        }
      }
    }

    .footer {
      width: 270px;
      margin: 5px auto;
      display: flex;
      text-align: center;
      justify-content: space-around;

      .btn {


        width: 186px;

        border-radius: 18px;
      }

      .forget {
        cursor: pointer;
        padding-top: 20px;
        font-size: 12px;
        color: rgba(0, 0, 0, .7);
        display: table-cell;
        vertical-align: bottom;
      }
    }

    .wei {
      margin: 25px auto;
      text-align: center;

      .wei_logo {

        display: flex;
        justify-content: space-around;
        margin-top: 12px;
        cursor: pointer;

        .cls-1 {
          fill: #07c160;
          fill-rule: evenodd;
        }

        .cls-1l {
          fill: #e78d00;
          fill-rule: evenodd;
        }
      }
    }

    .toggle {
      display: flex;
      justify-content: space-between;

      .btn {
        cursor: pointer;

      }

    }
  }

</style>
