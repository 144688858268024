/**
* @Description 龙芯应用公社
* @author Loongson.Quzhenhong
* @date 21-7-6
*/
<template>
  <div class="header" ref="header">
    <div class="nav">
      <img class="logo_img" :src="logo" alt="">
      <div class="search">
        <!-- el-select 标签被全局样式覆盖，转为div设置       -->
        <el-select
            v-model="value"
            filterable
            remote
            reserve-keyword
            placeholder="请输入关键词"
            :filter-method="searchClick"
            :remote-method="remoteMethod"
            :loading="loading"
            @focus="removeSelectReadOnlyProperty"
            style="width: 100%"
            v-on:keyup.enter.native="searchClick"
            suffix="">
          <el-option
              v-for="(item,index) in options"
              :key="index"
              :label="item.label"
              :value="item.value"
              @click.native="goDetail(item)"
          >
          </el-option>
        </el-select>
      </div>

      <div class="profile">
        <div class="logo">
          <img :src="logoUrl? logoUrl : constUrl" alt="">
          <div class="detail" v-if="token">
            <div class="de_top">
              <div class="de_top_l">
                <img :src="logoUrl? logoUrl : constUrl" alt="">
              </div>
              <div class="de_top_r">
                <div style="margin-bottom: 12px;">{{nickname}}</div>
                <div style="font-size: 10px;">龙芯号:{{loongsonAccount}}</div>
              </div>

            </div>
            <div class="de_content">
              <div class="de_content_item" @click="$router.push('/profile')">
                <i class="iconfont icon-wutouxiang"></i>
                个人资料
              </div>
            </div>

          </div>
        </div>
        <span class="login" @click="loginShow">{{token ? '退出' : '登录/注册'}}</span>
      </div>

    </div>
    <div class="menu">
      <router-link v-for="(item, index) in navs" :key="item.path" :to="item.path">
        <div :class="[currentPath==item.path?'act':'']" class="item">{{item.name}}</div>
      </router-link>
    </div>
    <div class="imgNav" v-if="bannerDataList.length&&(this.currentPath==='/home')">
<!--      <i class=" icon iconfont icon-zuojiantou" @click="toLeft"></i>-->
      <div class="imgShow" ref="content">

<!--        <div class="item" v-for="(item, index) in bannerDataList" :key="index" @click="clickNav(index)"-->
<!--             @mouseenter="hoverNav(index)"-->
<!--             :class="index === currentIndex ? 'outline':''">-->
<!--          <img class="img_item" :src="item.logoUrl" alt="">-->
<!--          <div class="dialog" v-show="!(index === currentIndex)"></div>-->
<!--        </div>-->

      </div>
<!--      <i class="icon iconfont icon-youjiantou" @click="toRight"></i>-->
    </div>
    <el-dialog
            :visible.sync="loginDialogVisible"
            width="30%"
            :before-close="handleClose">

      <div class="logined">
        <div class="logo1">
          <img :src="redLogo" alt="">
        </div>
        <div class="l_nav">
          <div class="tol">
            <div :class="[ isLogin? 'isLogin' :'']" class="login_l" @click="changeLogin">登录</div>
            <div :class="[ isLogin? '' :'isLogin']" class="register_r" @click="changeLogin2">注册</div>
          </div>
        </div>
        <Login v-if="isLogin"/>
        <Register v-else/>


      </div>
      <div class="alter">
        点击注册或登录代表您同意《龙芯网络服务使用协议》&nbsp;&&nbsp;《龙芯隐私政策》
      </div>

    </el-dialog>
    <!-- <el-dialog
             title="微信扫码登录"
             class="weixin"
             :visible.sync="qrcodeVisible"
             width="18%"
            :before-close="weinDialogClose">
       <div id="qrcode" ref="qrcode"></div>
     </el-dialog>-->
  </div>
</template>

<script>
  import Register from "./login/Register"
  import Login from './login/Login'
  import {getSearcherSoft, getHotSoft, getArticleSearcherSoft} from '@/utils/network'
  import {imgUrl} from '@/utils/imgUrl'
  import conf from "@/utils/conf";
  export default {
    name: 'Header',
    data() {
      return {
        options: [],
        articleOpt:[],
        value: [],
        loading: false,
        //导航数组
        navs: [
          {
            path: '/home',
            name: '首页'
          }, {
            path: '/hot',
            name: '热门软件推荐'
          },
          {
            path: '/cate',
            name: '软件分类'
          }, {
            path: '/install',
            name: '装机必备'

          },
          {
            path: '/speak',
            name: '热门帖子'
          },
          {
            path: '/new',
            name: '最新上架'
          },
          {
            path: '/email',
            name: '联系我们'
          }


        ],
        //轮博索引
        currentIndex: 0,
        //二维码弹框控制
        //qrcodeVisible:false,
        x: 0,
        //搜索框内容
        searchContent: '',
        searchList: [],
        token: null,
        constUrl: imgUrl,
        //头部轮播图数据
        bannerDataList: [],
        bgUrl: conf.getBannerImg(),
        logo:conf.getLogoImg(),
        redLogo:conf.getRedLogoImg()
      }
    },
    computed: {
      currentPath() {
        return this.$route.path
      },
      isLogin() {
        return this.$store.state.isLogin
      },
      loginDialogVisible() {
        return this.$store.state.loginDialogVisible
      },
      logoUrl() {
        return this.$store.state.avatar
      },
      loongsonAccount() {
        return this.$store.state.loongsonAccount
      },
      nickname() {
        return this.$store.state.nickname
      },


    },
    mounted() {
      this.$nextTick(() => {
        this.$refs.header.style.backgroundImage = `url(${this.bgUrl})`
      })
    },
    created() {
      //backgroundImage=`url(${this.bgUrl})`
      this.token = localStorage.getItem('token')
      this.gerBannerData()
    },
    components: {
      Register,
      Login
    },
    updated() {
      this.token = localStorage.getItem('token')
    },

    methods: {
      removeSelectReadOnlyProperty(){
        Array.from(document.getElementsByClassName('el-select')).forEach((item) => {
          item.children[0].children[0].removeAttribute('readOnly')
          item.children[0].children[0].onblur = function () {
            let _this = this
            setTimeout(() => {
              _this.removeAttribute('readOnly')
            }, 200)
          }
        })
      },
      remoteMethod(query) {
        this.value = query;
        if (query !== '') {
          this.loading = true;
          setTimeout(() => {
            this.loading = false;
            this.getData(query);
          }, 200);
        } else {
          this.options = [];
        }
      },
      async getData(query) {
        if (query !== '') {
          var datalist;
          const {data: res} = await getSearcherSoft({
            search_content: query,
            page:1,
            per_page:9,
            sourceType:'web'
          })
          if (res.code !== 200) this.$message.error('请求失败')
          // console.log(res)
          datalist = res.data.vodata.map(voitem=>{
            return { id:voitem.id,value: `${voitem.name}`, label: '应用：'+`${voitem.name}` };
          });
          const {data: res1} = await getArticleSearcherSoft({
            search_content: query,
            page:1,
            per_page:9,
          })
          if (res1.code !== 200) this.$message.error('请求失败')
          this.articleOpt = res1.data.vodata.map(voitem=>{
            return { id:voitem.id,value: `${voitem.title}`, label: '帖子：'+`${voitem.title}` };
          });
          datalist = datalist.concat(this.articleOpt);
        }else {
          datalist = [];
        }
        this.options = datalist;
        console.log('options:',this.options)
      },
      async gerBannerData() {
        const {data: res} = await getHotSoft({
          page: 1,
          per_page: 8,
          sourceType:'web'
        })
        if (res.code !== 200) return this.$message.error('请求失败')
        this.dataList = res.data.vodata
        this.bannerDataList = res.data.vodata.splice(0, 7)
      },

      changeLogin() {
        this.$store.dispatch('isLogin', {
          isLogin: true
        })
      },
      changeLogin2() {
        this.$store.dispatch('isLogin', {
          isLogin: false
        })
      },

      //点击搜索按钮
      async searchClick() {
        if (this.value.trim() === '') return
        await this.$store.dispatch('search', {
          keyword: this.value.slice(0, 99)
        })
        this.value = ''
        this.options = [];

        if (this.$route.path === '/search') return
        await this.$router.push('/search')
      },
      //header imgNav 移动事件
      toLeft() {
        if (this.currentIndex <= 0) return
        this.currentIndex -= 1
        if (this.x <= 0) return
        this.x -= 223
        this.listScroll(this.x)
      },
      hoverNav(index) {
        this.currentIndex = index
      },
      toRight() {
        if (this.currentIndex >= this.bannerDataList.length - 1) return
        this.currentIndex += 1
        if (this.x >= 1314) return
        this.x += 223
        this.listScroll(this.x)
      },
      listScroll(x) {
        this.$nextTick(() => {
          this.$refs.content.scrollTo({
            top: 0,
            left: x,
            behavior: "smooth"
          })
        })
      },
      /* weinDialogClose() {
         this.qrcodeVisible = false
         this.$refs.qrcode.innerHTML = ''

       },*/


      clickNav(index) {
        this.currentIndex = index
        const id = this.bannerDataList[this.currentIndex].id
        this.isShow = false;
        this.$router.push(`/detail/${id}`)
      },
      async loginShow() {
        if (!this.token) {
          this.$store.dispatch('changeDialogShow', {
            loginDialogVisible: true
          })
        } else {
          const confirmResult = await this.$confirm(
              '确认退出?',
              '提示',
              {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
              }
          ).catch(err => err)
          if (confirmResult !== 'confirm') {
            return this.$message.info('已取消退出！')
          }
          localStorage.removeItem('token')
          window.location.reload()
        }

      },
      //弹框关闭事件
      handleClose() {
        this.$store.dispatch('changeDialogShow', {
          loginDialogVisible: false
        })
        this.$bus.$emit('loginDialogClose')
      },
      goDetail(app){
        console.log('app:',app)
        this.value=''
        this.options=[]
        if(app.label.indexOf("应用：")!==-1){
          this.$router.push(`/detail/${app.id}`)
        }else{
          this.$router.push(`/detailPost/${app.id}`)
        }
      }
    },
  }
</script>

<style lang="less" scoped>
  .header {
    position: relative;
    height: 130px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    background-size: cover;
    // background-image: url("../images/banner.jpg");
    //background-position:100% 50%;
    background-repeat: no-repeat;
    padding-top: 18px;

    .nav {
      width: 80%;
      display: flex;
      justify-content: space-between;

      .logo_img {
        margin-top:10px;
        vertical-align: middle;
        width: 246px;
        height: 28px;
      }

      .search {
        width: 20%;
        .button {
          display: flex;
          align-items: center;
          line-height: 30px;
          width: 20%;
          min-width: 85px;
          cursor: pointer;
          height: 30px;
          border-radius: 0 10px 10px 0;
          background-color: #fff;

          span {
            margin-right: 10px;
            color: #ccc;
          }

          .font {
            margin-left: 10px;
            font-size: 14px;
            color: #ef0309;
          }
        }
      }

      .profile {
        color: #fff;
        display: flex;
        align-items: center;

        .logo {
          position: relative;

          img {
            width: 40px;
            height: 40px;
            border-radius: 50%;
          }

          &:hover {
            .detail {
              width: 220px;
              display: block;
              transition: all .3s;
            }
          }

          .detail {
            display: none;
            position: absolute;
            width: 0;
            height: 140px;
            border-radius: 20px;
            top: 40px;
            left: -80px;
            transition: all .3s;
            background-color: #fff;
            z-index: 999;

            &:hover {
              display: block;
            }

            .de_top {
              border-radius: 20px 20px 0 0;
              width: 100%;
              background-color: red;
              height: 60px;
              display: flex;
              //justify-content: space-around;
              align-items: center;

              .de_top_l {
                img {
                  width: 50px;
                  height: 50px;
                  border-radius: 50%;
                  margin: 0 6px 0 8px;
                }
              }

              .de_top_r {
                width: 180px;
              }
            }

            .de_content {
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: space-around;

              .de_content_item {
                cursor: pointer;
                height: 50px;
                line-height: 50px;
                text-align: center;
                font-size: 18px;
                color: #aaa;
                width: 100%;
                margin-top: 12px;

                &:hover {
                  color: #fff;
                  background-color: red;
                }
              }
            }

          }
        }

        span {
          margin-left: 25px;
          cursor: pointer;
        }
      }
    }

    .menu {
      margin-top: 20px;
      font-size: 14px;
      color: #fff;
      width: 76%;
      display: flex;
      justify-content: space-around;

      a {
        color: #fff;
      }

      .act {
        background-color: #fff;
        color: #2f2f2f;
        border-radius: 12px;
      }

      .item {
        height: 26px;
        line-height: 26px;
        text-align: center;
        padding: 0 12px;
        cursor: pointer;
        font-size:18px;
      }
    }

    .imgNav {
      min-width: 1224px;
      color: #fff;
      display: flex;
      align-items: center;
      position: absolute;
      bottom: 20px;
      width: 80%;
      padding: 0 6px;

      //.icon {
      //  cursor: pointer;
      //  //margin-right: 4px;
      //  font-size: 30px;
      //  height: 80px;
      //  line-height: 80px;
      //  background-color: rgba(0, 0, 0, .3);
      //}
      //
      //.imgShow {
      //  margin: 0 6px;
      //  display: flex;
      //  //justify-content: space-around;
      //  flex-wrap: nowrap;
      //  //justify-content: space-around;
      //  //width: 100%;
      //  // overflow: scroll;
      //  overflow-x: scroll;
      //  overflow-y: hidden;
      //
      //  &::-webkit-scrollbar {
      //    display: none;
      //  }
      //
      //  .outline {
      //    border: 1px solid #fff;
      //  }
      //
      //  .item {
      //
      //    position: relative;
      //    margin-right: 25px;
      //    box-sizing: border-box;
      //    width: 219px;
      //    height: 131px;
      //
      //    .dialog {
      //      position: absolute;
      //      left: 0;
      //      top: 0;
      //      width: 217px;
      //      height: 129px;
      //      background-color: rgba(0, 0, 0, .6);
      //    }
      //
      //    .img_item {
      //      width: 217px;
      //      height: 129px;
      //    }
      //
      //  }


      //}
      //
      //.img_item {
      //  box-sizing: border-box;
      //  width: 219px;
      //  height: 131px;
      //}

    }

    .isLogin {
      border-radius: 16px;
      background-color: #f0141a;
      text-align: center;
      color: #fff !important;
    }

    .el-dialog {
      padding: 0;
      margin: 0;
      width: 462px;

      .logined {
        height: 453px;

        .logo1 {
          display: flex;
          flex-direction: column;
          align-items: center;

          img {
            //width: 119px;
            height: 32px;
          }
        }

        .l_nav {
          display: flex;
          flex-direction: column;
          align-items: center;

          .tol {
            text-align: center;
            display: flex;
            width: 160px;
            margin: 18px 0;
            border-radius: 16px;
            background-color: #eaeaea;
            height: 33px;
            color: #f0141a;
            font-size: 18px;

            .login_l {
              width: 50%;
              line-height: 33px;
              cursor: pointer;
            }

            .register_r {
              width: 50%;
              line-height: 33px;
              cursor: pointer;
            }
          }
        }

      }

      .alter {
        font-size: 12px;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 30px;
        color: rgba(0, 0, 0, .7);
      }

      .weixin {
        margin-top: 40px;
      }

    }

  }

</style>
