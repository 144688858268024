/**
 * @Description 龙芯应用公社
 * @author Loongson.Quzhenhong
 * @date 21-7-6
*/
<template>
  <div id="home">
    <Home/>
    <router-view></router-view>
    <Footer/>
  </div>
</template>

<script>
  import Home from '@/components/Header.vue'
  import Footer from '@/components/Footer.vue'
  export default {
    components: {
      Home,
      Footer
    }
  }
</script>

<style lang="less" scoped>
  #home {
    min-width: 980px;
    background-color: #e5e5e5;
  }
</style>
